<template>
  <div class="container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" @click="goBackBtn">
      <Icon class="back_icon" type="ios-arrow-back"/>
      <span class="back_txt">{{ $t('key1001867') }}</span>
    </div>
    <div class="messageMailboxes_box">
      <div class="title_box">
        <h2 class="title">{{ messageTitle }}</h2>
      </div>
      <p class="time_box">{{ messageTime }}</p>
      <div class="conter_box" v-html="messageConter" @click="viewrOderDetails"></div>
    </div>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import {isJSON} from "@/utils/common";

export default {
  name: "messageMailboxes",
  data() {
    return {
      messageTitle: '',
      messageTime: '',
      messageConter: ''
    };
  },
  created() {
    this.getDetailsData();
  },
  methods: {
    // 返回列表
    goBackBtn() {
      this.$router.push('/messageCenter');
    },
    // 获取详情数据
    getDetailsData() {
      let v = this;
      let ymsReceiveStationNotificationLogId = v.$route.query.ymsReceiveStationNotificationLogId;
      v.axios.get(api.get_ymsSupplierStationNotification + ymsReceiveStationNotificationLogId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            v.messageTitle = data.title;
            v.messageTime = v.$uDate.dealTime(data.updatedTime, 'fulltime');
            if (data.informationType === 17) {
              let obj = {};
              let content = data.content;
              if (isJSON(content)) {
                obj = JSON.parse(content);
              }
              data.content = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000524')}${obj.supplierSpu}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000525')}${obj.shelvedRejectReason}。`
            }
            v.messageConter = data.content;
          }
        }
      });
    },
    // 查看订单详情
    viewrOderDetails(event) {
      let v= this;
      let id = event.target.id;
      if (document.getElementById(id)) {
        let packageCode = document.getElementById(id).innerText;
        if (packageCode) {
          v.$store.commit('changeTopMenu', true);
          localStorage.setItem('activeName', 0);
          setTimeout(() => {
            v.$router.push({
              path: '/wms/searchPackageDetails',
              query: {
                pickingNo: packageCode,
                workShow: 'detail',
                workType: "sellStock",
                switchTalg: 'message'
              }
            });
          }, 1000);
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.messageMailboxes_box {
  background-color: #fff;
  padding: 15px 20px;
  min-height: 80vh;

  .title_box {
    display: flex;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }
  }

  .time_box {
    color: #CDCDCD;
    font-size: 14px;
    margin: 8px 0;
  }

  .conter_box {
    border-top: 1px solid #E8E8E8;
    padding: 15px 0;
    font-size: 14px;
    color: #333;

    /deep/ .blueColor {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
